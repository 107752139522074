import React from "react"
import { PageProvider } from "../context/pageProvider"
import { useLocation } from "@reach/router"
import Seo from "../components/seo"
import BlogPage from "../components/blogPage"

const blogPageContainer = function ({ pageContext, location }) {
  return (
    <PageProvider data={pageContext} location={location}>
      <BlogPage />
    </PageProvider>
  )
}
export default blogPageContainer

export const Head = ({ pageContext }) => {
  const location = useLocation()
  return (
    <PageProvider data={pageContext} location={location}>
      <Seo
        seo={{
          author: "Addictive Media AB",
          description: "Blog",
          lang: "sv",
          title: "Blog",
          _type: "seo",
        }}
        children={<meta name="robots" content="noindex, nofollow"></meta>}
      />
    </PageProvider>
  )
}
