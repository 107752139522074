import * as React from "react"

import { PageContext } from "../context/pageProvider"
import Navigation from "./navigation"
import Footer from "./Footer/Footer"
import { getImageUrl } from "../utils/getImageUrl"
import { blockToContent } from "../helpers"
import SidebarForm from "./Form/SidebarForm"

const BlogPage = () => {
  const { page, meta } = React.useContext(PageContext)
  let postList = []
  if (meta.blogList?.blogListField?.length) {
    meta.blogList.blogListField.forEach(element => {
      const result = page.find(page => page._id === element._ref)
      postList.push(result)
    })
  } else {
    postList = [...page]
  }
  return (
    <div className="h-screen flex flex-col">
      <Navigation />
      <div
        style={{
          paddingTop: `${meta.settings.hero?.heroPadding}`,
        }}
        className={`${meta.settings.callInHeader ? "mt-[30px] md:mt-0" : ""}`}
      />
      <div className="pt-20"></div>
      <h2 className="text-4xl sm:text-5xl lg:text-7xl font-bold text-center pt-12 pb-16 px-4">
        {meta.blogList?.blogTitle?.length ? meta.blogList.blogTitle : "Blogg"}
      </h2>
      <div className="content_sidebar elva flex justify-between lg:px-8 gap-[10px]">
        <div>
          <div className="elva grow text-center">
            <div
              className={`flex mx-auto justify-center sm:justify-between flex-wrap gap-2`}
            >
              {postList.map(post => (
                <div
                  onClick={() =>
                    (window.location.pathname += `/${post.slug.current}`)
                  }
                  className="w-full sm:w-[48%] mb-5 cursor-pointer"
                >
                  <div className="h-[176px] truncate">
                    <img
                      src={
                        post.hero?.bgImage ||
                        getImageUrl(post.hero?.bgimage?.asset?._ref)
                      }
                    />
                  </div>
                  <h4 className="my-3">{post.title}</h4>
                </div>
              ))}
            </div>
          </div>
        </div>
        <SidebarForm />
      </div>
      <div className="-mt-8">
        <Footer />
      </div>
    </div>
  )
}

export default BlogPage
